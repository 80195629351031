import {authStore} from "./authStore";
import {decamelizeKeys, decamelize, camelizeKeys} from "humps";
import _ from 'lodash';
import {makeObservable, observable, action, runInAction} from 'mobx';

class BaseStore {
    defaultSortColumn = 'id';
    defaultSortOrder = 'desc';

    data = [];
    sortField = '';
    sortOrder = this.defaultSortOrder;
    filters = {};
    currentPage = 1;
    searchTerm = '';
    itemsPerPage = 20;
    totalItems = 0;
    lastPage = 0;
    loading = false;
    isFirstLoadPage = false;
    selectedToApproveDecline = [];
    errors = '';
    errorDetails = ''

    constructor() {
        makeObservable(this, {
            data: observable,
            sortField: observable,
            searchTerm: observable,
            sortOrder: observable,
            filters: observable,
            currentPage: observable,
            itemsPerPage: observable,
            totalItems: observable,
            lastPage: observable,
            loading: observable,
            isFirstLoadPage: observable,
            errors: observable,
            selectedToApproveDecline: observable,
            setSortField: action,
            setSearchTerm: action,
            setFilters: action,
            setCurrentPage: action,
            setItemsPerPage: action,
            loadData: action,
            addModel: action,
            editModel: action,
            deleteModel: action,
            resetStore: action
        });
    }

    async loadData(api) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isFirstLoadPage = false;
            });

            const selectedDepartmentId = authStore.selectedDepartmentId ?? '';

            const queryParams = {
                department_id: selectedDepartmentId ? selectedDepartmentId : '',
                sort_by: this.sortField ? decamelize(this.sortField) : this.defaultSortColumn,
                sort_dir: this.sortOrder,
                page: this.currentPage,
                pagination_size: this.itemsPerPage,
                search_term: this.searchTerm,
                ..._.omitBy(this.filters, value => value === undefined || value === null || value === "")
            };

            const response = await api.getAll(queryParams);

            runInAction(() => {
                this.data = response.data.data ?? response.data;
                this.totalItems = response.data.total;
                this.itemsPerPage = response.data.perPage;
                this.lastPage = response.data.lastPage;
            });
        } catch (error) {
            if (error && error.code) {
                if (error?.code === 'ERR_CANCELED') {
                    return
                }
            }

            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                return error?.response?.data?.message ?? error;
            });

            console.error("Loading expenses failed", error);
        } finally {
            this.loading = false;
            this.isFirstLoadPage = false;
        }
    }

    resetStore() {
        this.data = [];
        this.sortField = '';
        this.sortOrder = 'desc';
        this.filters = {};
        this.currentPage = 1;
        this.searchTerm = '';
        this.itemsPerPage = 20;
        this.totalItems = 0;
        this.lastPage = 0;
        this.loading = false;
        this.isFirstLoadPage = false;
        this.errors = '';
        this.errorDetails = ''
    }

    resetErrorsStore(withValidError = true) {
        this.errors = '';

        if (withValidError) {
            this.errorDetails = ''
        }
    }

    resetSort() {
        runInAction(() => {
            this.sortOrder = this.defaultSortOrder;
            this.sortField = this.defaultSortColumn;
        });
    }

    setSortField(field) {
        runInAction(() => {
            if (this.sortField === field) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortField = field;
                this.sortOrder = 'asc';
            }
        });

        this.loadData();
    }

    setSearchTerm(search) {
        runInAction(() => {
            this.searchTerm = search;
        });
        //  this.loadData();
    }

    setFilters(filters, isCleanSearch = false) {
        runInAction(() => {
            this.searchTerm = isCleanSearch ? '' : this.searchTerm;
            this.filters = filters;
        });
        //    this.loadData();
    }

    setCurrentPage(page) {
        runInAction(() => {
            this.currentPage = page;
        });
        this.loadData();
    }

    setItemsPerPage(count) {
        runInAction(() => {
            this.itemsPerPage = count;
        });
    }

    async addModel(api, model, formatData = true, methodLoadData = false) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });

            //  model.userId = authStore.user.id;
            const copyModel = _.cloneDeep(model);
            const formatedModel = _.omitBy(copyModel, (value) => value === null || value === undefined || value === '');
            const decamelizedData = decamelizeKeys(formatedModel);

            if (formatData) {
                let formData = new FormData();
                for (const key in decamelizedData) {
                    if (key !== 'attachment_file' && key !== 'user_image') {
                        formData.append(key, decamelizedData[key]);
                    }
                }

                if (formatedModel.attachmentFile) {
                    formData.append('attachment_file', formatedModel.attachmentFile[0]);
                }

                if (formatedModel.userImage) {
                    formData.append('user_image', formatedModel.userImage[0]);
                }
                await api.create(formData);
            } else {
                await api.create(decamelizedData);
            }

            if (methodLoadData) {
                this[methodLoadData]();
            } else {
                this.loadData();
            }
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.errorDetails = camelizeKeys(error.response.data.data ?? error);
                this.loading = false;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
            throw error;
        } finally {
            runInAction(() => {
                //  this.loading = false;
                this.isLoading = false;
            });
        }
    }

    async editModel(api, id, model, formatData = true, methodLoadData = false) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });

            // model.userId = authStore.user.id;
            const copyModel = _.cloneDeep(model);
            const formatedModel = _.omitBy(copyModel, (value) => value === null || value === undefined || value === '');
            const decamelizedData = decamelizeKeys(formatedModel);

            if (formatData) {
                let formData = new FormData();

                for (const key in decamelizedData) {
                    if (key !== 'attachment_file' && key !== 'user_image') {
                        formData.append(key, decamelizedData[key]);
                    }
                }

                if (formatedModel.attachmentFile) {
                    formData.append('attachment_file', formatedModel.attachmentFile[0]);
                }

                if (formatedModel.userImage) {
                    formData.append('user_image', formatedModel.userImage[0]);
                }

                await api.update(id, formData);
            } else {
                await api.update(id, decamelizedData);
            }

            if (methodLoadData) {
                this[methodLoadData]();
            } else {
                this.loadData();
            }
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.errorDetails = camelizeKeys(error.response.data.data ?? error);
                this.loading = false;
                this.isLoading = false;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
            throw error;
        } finally {
            runInAction(() => {
                //  this.loading = false;
                this.isLoading = false;
            });
        }
    }

    async deleteModel(api, id, methodLoadData = false) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });
            this.data = this.data.filter(model => model.id !== id);
            const response = await api.delete(id);

            if (methodLoadData) {
                this[methodLoadData]();
            } else {
                this.loadData();
            }
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.errorDetails = camelizeKeys(error.response.data.data ?? error);
                this.loading = false;
                this.isLoading = false;
                return error?.response?.data?.message ?? error;
            });
            throw error;
        } finally {
            runInAction(() => {
                //   this.loading = false;
                this.isLoading = false;
            });
        }
    }

    async export(api, userId) {
        if (!userId) {
            return
        }

        try {
            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });

            const params = {
                userId: userId
            }

            const response = await api.export(params);

            return response
        } catch (error) {
            this.error = error.message;
        } finally {
            runInAction(() => {
                this.loading = false;
                this.isLoading = false;
            });
        }
    }

    togglePermission(itemId) {
        runInAction(() => {
            const index = this.selectedToApproveDecline.indexOf(itemId);

            if (index === -1) {
                this.selectedToApproveDecline.push(itemId);
            } else {
                this.selectedToApproveDecline.splice(index, 1);
            }
        })
    }

    togglePermissionAll(status) {
        runInAction(() => {
            if (status) {
                this.selectedToApproveDecline = this.data.map(item => item.id);
            } else {
                this.selectedToApproveDecline = [];
            }
        });
    }

    resetSelectedToApproveDecline() {
        this.selectedToApproveDecline = [];
    }

    async multiApproveDecline(api, ids, action, methodLoadData) {
        try {
            if (!ids || !action) {
                return
            }

            runInAction(() => {
                this.loading = true;
                this.isLoading = true;
            });

            const form = {
                id: ids,
                action: action
            }

            await api.acceptReject(form);

            if (methodLoadData) {
                this[methodLoadData]();
            } else {
                this.loadData();
            }
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.errorDetails = camelizeKeys(error.response.data.data ?? error);
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
            throw error;
        } finally {
            runInAction(() => {
                this.loading = false;
                this.isLoading = false;
            });
        }
    }

}

export default BaseStore;