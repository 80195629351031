import React from 'react';
import Select from "react-select";
import {Form} from "react-bootstrap";

const CustomSelect = ({
                          options,
                          store,
                          column,
                          filters,
                          setFilters,
                          selectedFilter,
                          setSelectedFilter,
                          styleClass = 'custom-select',
                          loadMethod = '',
                      }) => {
    const handleSelectFilterChange = (selectedOptions, column, isMulti = false) => {
        if (isMulti) {
            setSelectedFilter(selectedOptions || []);
            let itemId = '';

            if (selectedOptions) {
                itemId = selectedOptions.map((item) => {
                    return item.value;
                })
            }

            const newFilters = {
                ...filters,
                [column]: itemId && itemId.length ? itemId : ''
            };

            setFilters(newFilters);
            store.setFilters(newFilters);
            store.loadData(loadMethod)

        } else {
            setSelectedFilter(selectedOptions || '');
            let itemId = '';

            if (selectedOptions) {
                itemId = selectedOptions?.value ?? '';
            }

            const newFilters = {
                ...filters,
                [column]: itemId ? itemId : ''
            };

            setFilters(newFilters);
            store.setFilters(newFilters);
            store.loadData(loadMethod)
        }
    };

    const customStyles = {
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',

        }),
        control: (provided, state) => ({
            ...provided,
            padding: '0px',
        }),
    };

    return (
        <div>
            <Form.Group controlId="selectedFilter">
                <Select
                    isClearable
                    value={selectedFilter}
                    onChange={(e) => handleSelectFilterChange(e, column)}
                    options={options}
                    className={`basic-multi-select custom-select ${styleClass}`}
                    name="selectedFilter"
                    classNamePrefix=""
                    styles={customStyles}
                />
            </Form.Group>
        </div>
    );
};

export default CustomSelect;
