import React, {useEffect} from "react";
import BreakManagementList from "../components/timeTracking/BreakManagementList";

const BreakManagementPage = () => {
    useEffect(() => {
        document.title = 'Break Management';
    }, []);

    return (
        <div className={'wrap-page'}>
            <BreakManagementList/>
        </div>
    );
};

export default BreakManagementPage;
