import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Button, Spinner, Form} from "react-bootstrap";
import Modal from "../common/Modal";
import {
    formatDateToInput,
    convertDateTimeUTCToLocal, addSecondsIfMissing, timeToSeconds
} from '../../utils/mainUtils';
import {format} from "date-fns";

const TimeTrackerAdd = observer(({show, onClose, onAdd, onEdit, isSubmitting, data = null, recordDateTime}) => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const currentTime = format(new Date(), 'HH:mm:ss');

    const initialModelData = {
        recordTime: '',
        recordDate: '',
        scanType: 'out'
    };

    const initialErrors = {
        recordTime: '',
        recordDate: ''
    };

    const [modelData, setModelData] = useState(initialModelData);
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        if (!show) {
            setModelData(initialModelData);
            setErrors(initialErrors);
        }
    }, [show]);

    const handleDateChange = (e) => {
        const {name, value} = e.target;
        setModelData(prevData => ({...prevData, [name]: value}));
    }

    const handleTimeChange = (e) => {
        const {name, value} = e.target;
        const formatedTime = value ? value : '';
        setModelData(prevData => ({...prevData, [name]: formatedTime}));
    }

    const validateForm = async () => {
        const validationPromises = Object.keys(modelData).map(fieldName =>
            validateField(fieldName, modelData[fieldName])
        );
        const validationResults = await Promise.all(validationPromises);

        return validationResults.every(error => !error);
    }

    const validateField = (name, value) => {
        return new Promise((resolve) => {
            let errorMessage = '';
            switch (name) {
                case 'recordTime':
                    if (!value) {
                        errorMessage = 'Record Time is required';
                    } else if (
                        modelData.recordDate === today &&
                        timeToSeconds(addSecondsIfMissing(value)) > timeToSeconds(addSecondsIfMissing(currentTime))
                    ) {
                        errorMessage = 'Time cannot be later than the current time';
                    }
                    break;
                case 'recordDate':
                    if (!value) {
                        errorMessage = 'Record Date is required';
                    }
                    break;
                default:
                    break;
            }

            setErrors(prevErrors => ({...prevErrors, [name]: errorMessage}));
            resolve(errorMessage);
        });
    };

    const handleBlur = (fieldName, value) => {
        validateField(fieldName, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = await validateForm();
        if (isValid) {
            try {
                await onAdd(modelData, 'out');
                setModelData(initialModelData);
                setErrors(initialErrors);
            } catch (error) {
            }
        } else {
            console.log('Form validation failed');
        }
    };

    return (
        <Modal show={show} onClose={onClose} title={data ? 'Tracker time Expense' : 'Add Tracker time'}>
            <Form>
                <Form.Group controlId="formEmployeeName">
                    <Form.Group controlId="formRecordDateTime">
                        <Form.Label className={'label-in-modal'}>Tracker turn-on time:</Form.Label>
                        <label>{convertDateTimeUTCToLocal(recordDateTime)}</label>
                    </Form.Group>
                    <Form.Group controlId="formEndDate">
                        <Form.Label className={'label-in-modal'}>End Date</Form.Label>
                        <Form.Control
                            className={'select-in-modal'}
                            type="date"
                            name="recordDate"
                            value={modelData.recordDate ? formatDateToInput(modelData.recordDate) : ''}
                            onChange={handleDateChange}
                            onBlur={() => handleBlur('recordDate', modelData.recordDate)}
                            required
                            max={today}
                            isInvalid={!!errors.recordDate}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.expenseDateTo}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formrecordTime">
                        <Form.Label className={'label-in-modal'}>End Time</Form.Label>
                        <Form.Control
                            className={'select-in-modal'}
                            type="time"
                            name="recordTime"
                            value={modelData.recordTime ? modelData.recordTime : ''}
                            onChange={handleTimeChange}
                            onBlur={() => handleBlur('recordTime', modelData.recordTime)}
                            required
                            step="2"
                            max={currentTime}
                            isInvalid={!!errors.recordTime}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.recordTime}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>
            </Form>
            <Button
                className={'button-save'}
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {isSubmitting ? <Spinner as="span" animation="border" size="sm"/> : data ? "Edit" : "Save"}
            </Button>
        </Modal>
    );
});

export default TimeTrackerAdd;
