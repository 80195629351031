import React from 'react';
import '../../assets/styles/Modal.css';
import '../../assets/styles/CustomBootstrap.css'
import {Modal as BootstrapModal, Button} from 'react-bootstrap';

const Modal = ({show, onClose, children, title}) => {
    if (!show) {
        return null;
    }

    return (
        <BootstrapModal size={'md'} show={show} onHide={onClose}>
            <div className="custom-modal-content">
                <BootstrapModal.Header closeButton className="custom-modal-header">
                    <BootstrapModal.Title className="custom-modal-title">{title}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>{children}</BootstrapModal.Body>
            </div>
        </BootstrapModal>
    );
};

export default Modal;
