import React from 'react';
import {observer} from 'mobx-react-lite';
import {authStore} from "../../stores/authStore";
import defaultUserIcon from '../../assets/images/icons/user.png';
import iconHelp from '../../assets/images/icons/tabler-icon-help.svg';
import iconLogout from '../../assets/images/icons/tabler-icon-logout.svg';
import arrowLeftIcon from '../../assets/images/icons/arrow-left-icon.svg'
import TimeTracker from "../common/TimeTracker";
import {useLocation, useNavigate} from "react-router-dom";

const Topbar = observer(() => {
    const permissions = authStore.permissions;
    const navigate = useNavigate();
    const location = useLocation();

    const isDepartmentsPage = location.pathname.includes('departments') ||
        location.pathname.includes('global-config') ||
        location.pathname.includes('time-tracking')

    const onLogout = async () => {
        await authStore.logout();
    }

    const toDepartments = () => {
        if (permissions?.departmentManagement?.selectDepartment && permissions?.departmentManagement?.viewDepartment) {
            navigate('/departments')
        }
    }

    return (
        <div className="topbar">
            <div className={permissions?.departmentManagement?.selectDepartment &&
            permissions?.departmentManagement?.viewDepartment ?
                'department-name' :
                'department-name cursor-none no-hover-button'
            }>
                {!isDepartmentsPage && (<h1 onClick={() => toDepartments()}>
                    {permissions?.departmentManagement?.selectDepartment && permissions?.departmentManagement?.viewDepartment && (
                        <img src={arrowLeftIcon}/>)}
                    {authStore.selectedDepartmentName ?? ''}
                </h1>)}
            </div>
            {authStore.isAuthenticated && (
                <div className="user-info">
                    <TimeTracker/>
                    <span>{authStore.user?.name ?? ''}</span>
                    <div>
                        <img
                            src={authStore.user?.userImageFullUrl || defaultUserIcon}
                            alt="Avatar"
                            className="avatar"
                        />
                    </div>
                    <div>
                        <img src={iconHelp}></img>
                    </div>
                    <div className={'logout-button'}>
                        <img onClick={onLogout} src={iconLogout}></img>
                    </div>
                </div>
            )}
        </div>
    );
});

export default Topbar;
