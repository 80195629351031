import React, {useEffect} from "react";
import RoleAdd from "../components/globalConfig/RoleAdd";


const AddNewRolePage = () => {
    useEffect(() => {
        document.title = 'Global Config';
    }, []);

    return (
        <div className={'wrap-page'}>
            <RoleAdd/>
        </div>
    );
};

export default AddNewRolePage;
