import {makeAutoObservable} from "mobx";
import constantsApi from '../api/constantsApi';

class ConfigStore {
    configData = {
        defaultItemsPerPage: 20,
        reasons: [],
        statuses: [],
        userRoles: [
            {1: 'Employee'},
            {2: 'Super Admin'},
            {3: 'General Manager'},
            {4: 'Department Manager'},
        ],

        userStatuses: [],
        expencesReasons: [],
        expenseStatuses: [],
        remoteWorkType: [],
        remoteWorkStatuses: [],
        absenceType: [],
        absenceStatuses: [],
        designations: [],
        scanType: [
            'in',
            'out'
        ]
    };

    constructor() {
        makeAutoObservable(this);
        //  this.fetchConfigData();
    }

    async fetchConfigData() {
        try {
            const {authStore} = await import('./authStore');

            if (authStore !== null && typeof authStore !== 'undefined' && authStore.isAuthenticated) {
                const data = await constantsApi.constants();
                this.configData = {
                    ...this.configData,
                    ...data.data
                }

                this.configData = {
                    ...this.configData,
                    reasons: [
                        {'id': 1, 'reason': 'Gasoline'},
                        {'id': 2, 'reason': 'Diesel'}
                    ],
                    statuses: [
                        {'id': 1, 'status': 'Approved'},
                        {'id': 2, 'status': 'Pending'},
                        {'id': 3, 'status': 'Rejected'},
                    ]
                }
            }
            ;
        } catch (error) {
            console.error("Failed to fetch config data", error);
        }
    }
}

const configStore = new ConfigStore();
export default configStore;
