import React from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import leftShotArrowIcon from '../../assets/images/icons/left-short-arrow-icon.svg';

const BackButton = observer(({label = 'back'}) => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className={'d-flex align-items-center cursor-pointer'} onClick={handleBack}>
            <img src={leftShotArrowIcon}></img> {label}
        </div>
    );
});

BackButton.propTypes = {
    label: PropTypes.string.isRequired,
};

export default BackButton;
