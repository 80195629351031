import React from 'react';
import {Navigate} from 'react-router-dom';
import {authStore} from './stores/authStore';
import {observer} from 'mobx-react-lite';

const PrivateRoute = observer(({children}) => {
    return authStore.isAuthenticated ? (
        <>{children}</>
    ) : (
        <Navigate to="/login"/>
    );
});

export default PrivateRoute;
