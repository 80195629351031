import axiosConfig, {sendRequest} from './axiosConfig';
import {camelizeKeys, decamelizeKeys} from 'humps';

const usersApi = {
    getAll: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/users-list', {...formatedParams});
        return camelizeKeys(response.data);
    },
    getById: async (id) => {
        const response = await axiosConfig.get(`/users/${id}`);
        return camelizeKeys(response.data);
    },
    create: async (data) => {
        const response = await sendRequest('/users', 'post', data, {}, 'multipart/form-data');
        return camelizeKeys(response.data);
    },
    update: async (id, data) => {
        const response = await sendRequest(`/update-user/${id}`, 'post', data, {}, 'multipart/form-data');
        return camelizeKeys(response.data);
    },
    delete: async (id) => {
        const response = await axiosConfig.delete(`/users/${id}`);
        return camelizeKeys(response.data);
    },
    getUsersListMinimal: async (params) => {
        const formatedParams = decamelizeKeys(params)
        const response = await axiosConfig.post('/users-list-minimal', {...formatedParams});
        return camelizeKeys(response.data);
    },
};

export default usersApi;